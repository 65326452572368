<template>
  <div>
    <v-autocomplete
      v-model="personSelected"
      :items="personList"
      outlined
      dense
      return-object
      clearable
      item-text="nome_completo"
      item-value="id"
      :label="label"
      :rules="rules"
      :loading="loading"
      :item-disabled="isItemDisabled"
      :filter="filterObject"
      :readonly="readonly"
      @change="save"
    >
      <template v-slot:selection="data">
        {{ data.item.nome_completo }}
      </template>

      <template v-slot:no-data>
        <template>
          <v-list-item-content style="text-align: center">
            <v-list-item-title v-if="loadingSearch">
              Carregando...
            </v-list-item-title>
            <v-list-item-title v-else>
              Não há dados disponíveis
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

export default {
  props: {
    selectedPerson: {
      type: [Object, Array],
      default: () => {},
    },
    label: {
      type: String,
      default: 'Pessoa'
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      personSelected: {},
      personList: [],
      loading: true,
      loadingSearch: false,
      lastQuery: '',
      debounceFetchMorePersons: null,
    };
  },
  async mounted() {
    try {
      if (this.selectedPerson && Object.keys(this.selectedPerson).length > 0) {
        this.personSelected = this.selectedPerson;
        if (this.readonly) {
          this.personList = [this.personSelected];
        }
      }
      if (!this.readonly) {
        await this.loadPersons({ search: '' });
        this.personList = this.persons.data;
      }
      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("persons", ["persons"]),
  },
  methods: {
    ...mapActions("persons", ["loadPersons"]),

    save (value) {
      if (!this.readonly) {
        if (!value) value = {};
        this.$emit("save", value);
      }
    },

    isItemDisabled(item) {
      return item.id === null;
    },

    filterObject(item, queryText) {
      this.loadingSearch = true;
      const searchText = queryText.toLowerCase();
      let matches = null;
      if (item && item.id !== null) {
        matches = (
          item.nome_abreviado.toLowerCase().indexOf(searchText) > -1 ||
          item.nome_completo.toLowerCase().indexOf(searchText) > -1 ||
          item.nome_original.toLowerCase().indexOf(searchText) > -1
        );
      }

      if (!matches && this.lastSearchQuery !== searchText) {
        this.lastSearchQuery = searchText;
        if (!this.debounceFetchMorePersons) {
          this.debounceFetchMorePersons = debounce(this.loadPeronsFilter, 500);
        }
        this.debounceFetchMorePersons(searchText);
      }else {
        this.loadingSearch = false;
        return matches;
      }
    },
    async loadPeronsFilter() {
      if (!this.readonly) {
        this.loadingSearch = true;
        try {
          const params = { search: this.lastSearchQuery};
          await this.loadPersons(params);
          if (this.persons.data.length < 1) {
            this.personList = [{ nome_completo: "Nenhum usuário encontrado", id: null }];
          }else {
            this.personList = this.persons.data;
          }
          this.loadingSearch = false;
        } catch (error) {
          await this.$swal("Oops...", 'Erro ao buscar linhas', "error");
          this.personList = [{ nome_completo: "Nenhum usuário encontrado", id: null }];
        }
      }
    },
  },
  watch: {
    selectedPerson(newVal) {
      if (this.personSelected !== newVal) {
        this.personSelected = newVal;
      }
    }
  },
};
</script>
<style scoped></style>
