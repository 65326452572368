<template>
    <v-sheet elevation="2">
      <v-container>
        <v-card outlined class="pa-2" :loading="loading">
          <v-card-title class="text-h5"> Editar Colunas </v-card-title>
          <v-card-subtitle class="text-h6">Colunas que você adicionou</v-card-subtitle>
          <v-list dense>
            <draggable
              :list="localColumnsSelected"
              class="list-group"
              ghost-class="ghost"
              @change="hasChanges = true"
            >
              <v-hover v-slot="{ hover }" v-for="(element, index) in localColumnsSelected" :key="index">
                <v-list-item :class="{ 'hover-active': hover }">
                  <v-list-item-icon><v-icon>mdi-drag</v-icon></v-list-item-icon>
                  <v-list-item-content class="v-chip">{{ element.text }}</v-list-item-content>
                  <v-icon color="error" @click="removeDynamicColumn(element, index)">
                    mdi-close-circle
                  </v-icon>
                </v-list-item>
              </v-hover>
            </draggable>
          </v-list>
          <v-divider></v-divider>

          <v-card-subtitle class="text-h6 mt-2">Colunas que você pode adicionar</v-card-subtitle>
          <v-list dense>
            <v-list-item
              v-for="(column, index) in localAvailableColumns"
              :key="index"
            >
              <v-list-item-content>{{ column.text }}</v-list-item-content>
              <v-icon color="success" @click="addColumn(column, index)">
                mdi-plus-circle
              </v-icon>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-col cols="12" class="row">
            <v-btn color="secondary" style="margin: auto;" @click="resetChanges">Cancelar</v-btn>
            <v-btn color="primary" style="margin: auto;" :disabled="!hasChanges" @click="applyChanges">
              Aplicar
            </v-btn>
          </v-col>
        </v-card>
      </v-container>
    </v-sheet>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";

export default {
  components: { draggable },
  props: {
    columnsSelected: {
      type: Array,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      initialState: {},
      hasChanges: false,
      localColumnsSelected: [],
      localAvailableColumns: [],
      dragOptions: {
        animation: 200,
      },
      loading: true,
    };
  },
  mounted() {
    this.localColumnsSelected = JSON.parse(JSON.stringify(this.columnsSelected));
    this.localAvailableColumns = JSON.parse(JSON.stringify(this.availableColumns));
    this.filterAvailableColumns();
    this.saveInitialState();
    this.loading = false;
  },
  methods: {
    ...mapActions("user", ["saveUserColumnsSolicitation"]),

    filterAvailableColumns() {
      const dynamicNames = this.localColumnsSelected.map((col) => col.value);
      this.localAvailableColumns = [...this.localAvailableColumns].filter(
        (col) => !dynamicNames.includes(col.value)
      );
    },
    saveInitialState() {
      this.initialState = {
        columnsSelected: JSON.parse(JSON.stringify(this.localColumnsSelected)),
        availableColumns: JSON.parse(JSON.stringify(this.localAvailableColumns)),
      };
    },
    resetChanges() {
      this.localColumnsSelected = JSON.parse(JSON.stringify(this.initialState.columnsSelected));
      this.localAvailableColumns = JSON.parse(JSON.stringify(this.initialState.availableColumns));
      this.hasChanges = false;
    },
    applyChanges() {
      this.saveInitialState();
      this.$emit('change', this.localColumnsSelected);
      this.saveUserColumnsSolicitation(this.localColumnsSelected);
      this.hasChanges = false;
    },
    removeDynamicColumn(column, index) {
      this.localColumnsSelected.splice(index, 1);
      this.localAvailableColumns.push({ ...column });
      this.hasChanges = true;
    },
    addColumn(column, index) {
      this.localColumnsSelected.push({ ...column });
      this.localAvailableColumns.splice(index, 1);
      this.hasChanges = true;
    },
  },
};
</script>

<style scoped>
.hover-active {
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
</style>