<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-text>
      <div>Dados da Solicitação</div>
      <p class="headline text--primary">
        {{ getSelectedSolicitation.description }}
      </p>
      <p>Edite os campos para validar:</p>
      <v-row>
        <v-col cols="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div @click="confirmEdit()">
              <div
                v-for="(field,index_field) in selected_solicitation_validate_fields.data"
                :key="index_field"
                :class="{ 'disabled-click': isEdited }"
              >
                <ValidateTextComponent
                    v-if="field.type === 'text'"
                    :field="field"
                    :model="field.value"
                />
                <ValidateTextareaComponent
                    v-if="field.type === 'textarea'"
                    :field="field"
                    :model="field.value"
                />
                <ValidateSelectListComponent
                    v-if="field.type === 'selectList'"
                    :field="field"
                    :model="field.value"
                />
                <ValidateCompanyComponent
                    v-if="field.type === 'company'"
                    :field="field"
                    :model="field.selected ? field.selected : field.value"
                />
                <CustomRequesterComponent
                  v-if="field.type === 'requester'"
                  :selectedRequester="field"
                  :setClass="''"
                  :setCols="''"
                  :page="'validate'"
                  :groupUser="is_adm ? 1 : 0"
                ></CustomRequesterComponent>
                <ValidatePlansMultipleComponent
                    v-if="field.type === 'plans'"
                    :field="field"
                    @componentLoading="componentLoading"
                />
                <ValidatePackagesMultipleComponent
                    v-if="field.type === 'busca_pacote'"
                    :field="field"
                    @componentLoading="componentLoading"
                />
                <ValidateChangeDDDComponent
                    v-if="field.type === 'troca_ddd' || field.type === 'change_number_undue_divulgation'"
                    :field="field"
                    page="validate"
                    :titlePage="field.display_namne"
                    @componentLoading="componentLoading"
                />
                <ValidateDDDComponent
                    v-if="field.type === 'ddd'"
                    :field="field"
                    :model="field.selected"
                    @componentLoading="componentLoading"
                />
                <ValidateDateComponent
                    v-if="field.type === 'date'"
                    :field="field"
                    :model="field.value"
                />
                <ValidateNumberComponent
                    v-if="field.type === 'number'"
                    :field="field"
                    :model="field.value"
                />
                <ValidateLineComponent
                    v-if="field.type === 'linha'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <PackagesMultipleComponent
                    v-if="field.type === 'roaming'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <LineDisablePackage
                    v-if="field.type === 'line_disable_package'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <CancelLineComponent
                    v-if="field.type === 'cancel_line'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <SuspendLineComponent
                    v-if="field.type === 'suspend_line'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <ChangeChipComponent
                    v-if="field.type === 'change_chip'"
                    :field="field"
                    :model="field.value"
                    page="validate"
                    @componentLoading="componentLoading"
                />
                <ReactivateLineComponent
                    v-if="field.type === 'reactivate_line'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <VoluntaryLineSuspensionComponent
                    v-if="field.type === 'voluntary_line_suspension' || field.type === 'suspensao_perda_roubo'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <OnlineManagerComponent
                    v-if="field.type === 'update_gol'"
                    :field="field"
                    :model="field.value"
                    @componentLoading="componentLoading"
                />
                <ActivateNewLineComponent
                    v-if="field.type === 'activate_new_line'"
                    :field="field"
                    :model="field.value"
                    page="validate"
                    @componentLoading="componentLoading"
                />
              </div>
            </div>

            <v-sheet
              class="mx-auto mt-5 py-2"
              elevation="4"
            >
              <v-col class="col-md-12">
                <v-card-title class="subtitle-1 text--primary pb-0">
                  Revise o e-mail que será enviado ao fornecedor
                </v-card-title>
              </v-col>

              <v-col v-if="email_loading" class="col-md-12 d-flex justify-center align-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>

              <v-col class="col-md-12" v-if="!email_loading">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    label="Assunto"
                    v-model="subjectEmail"
                    @change="isEdited = true"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-col>
              <v-col cols="12" class="py-0" v-if="!email_loading">
                <v-col md="12" cols="12">
                  <quill-editor
                    :content="messageEmail"
                    :options="editorOption"
                    v-model="messageEmail"
                    @change="isEdited = true"
                  />
                </v-col>
              </v-col>
            </v-sheet>

            <v-row>
              <div class="col-md-12">
                <v-col class="col-md-2 float-right">
                  <v-btn
                      block
                      color="primary"
                      :loading="btn_loading || component_loading"
                      :disabled="btn_loading || !valid || email_loading"
                      @click="validate()"
                  >Validar</v-btn
                  >
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import ValidateSelectListComponent from "./validate_forms/ValidateSelectListComponent";
import ValidateRequesterComponent from "./validate_forms/ValidateRequesterComponent";
import ValidateCompanyComponent from "./validate_forms/ValidateCompanyComponent";
import ValidatePlansMultipleComponent from "./validate_forms/ValidatePlansMultipleComponent";
import ValidateTextComponent from "./validate_forms/ValidateTextComponent";
import ValidateTextareaComponent from "./validate_forms/ValidateTextareaComponent";
import ValidateDDDComponent from "./validate_forms/ValidateDDDComponent";
import ValidateDateComponent from "./validate_forms/ValidateDateComponent";
import ValidateNumberComponent from "./validate_forms/ValidateNumberComponent";
import ValidatePackagesMultipleComponent from "@/components/validate_forms/ValidatePackagesMultipleComponent";
import ValidateLineComponent from "@/components/validate_forms/ValidateLineComponent";
import ValidateChangeDDDComponent from "@/components/validate_forms/ValidateChangeDDDComponent";
import PackagesMultipleComponent from "@/components/customFieldsComponents/PackagesMultipleComponent";
import LineDisablePackage from "@/components/customFieldsComponents/LineDisablePackageComponent";
import CancelLineComponent from "@/components/customFieldsComponents/CancelLineComponent";
import SuspendLineComponent from "@/components/customFieldsComponents/SuspendLineComponent";
import ChangeChipComponent from "@/components/forms/CustomChangeChipComponent";
import ReactivateLineComponent from "@/components/customFieldsComponents/ReactivateLineComponent";
import VoluntaryLineSuspensionComponent from "@/components/customFieldsComponents/VoluntaryLineSuspensionComponent";
import OnlineManagerComponent from "@/components/customFieldsComponents/OnlineManagerComponent";
import ActivateNewLineComponent from "@/components/customFieldsComponents/ActivateNewLineComponent";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Swal from "sweetalert2";
import CustomRequesterComponent from "@/components/forms/CustomRequesterComponent.vue";

export default {
  props: ["is_adm"],
  components: {
    CustomRequesterComponent,
    quillEditor,
    ValidateSelectListComponent,
    ValidateCompanyComponent,
    ValidatePlansMultipleComponent,
    ValidatePackagesMultipleComponent,
    ValidateTextComponent,
    ValidateTextareaComponent,
    ValidateDDDComponent,
    ValidateDateComponent,
    ValidateNumberComponent,
    ValidateLineComponent,
    ValidateChangeDDDComponent,
    PackagesMultipleComponent,
    LineDisablePackage,
    CancelLineComponent,
    SuspendLineComponent,
    ChangeChipComponent,
    ReactivateLineComponent,
    VoluntaryLineSuspensionComponent,
    OnlineManagerComponent,
    ActivateNewLineComponent
  },
  async mounted() {
    try {
      const params = {
        solicitation_id: this.$route.params.solicitation_id,
        values: null,
        create_solicitaion: false,
        loadPageValidate: true
      }
      await Promise.all([
        this.loadSolicitationValidateFields(this.$route.params.solicitation_id),
        this.loadSolicitationFormEmail(params)
      ]);
      if (this.solicitation_form_email.success && this.solicitation_form_email.data && this.solicitation_form_email.data.subjectEmail !== null) {
        this.subjectEmail = this.solicitation_form_email.data.subjectEmail;
        this.messageEmail = this.solicitation_form_email.data.messageEmail;
        this.isEdited = this.solicitation_form_email.data.manualEditEmail;
        this.email_loading = false;
      }
      this.loading_email_mounted = false;
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
      await this.$router.push({
        name: "solicitation"
      });
    }

    if (this.selected_solicitation_validate_fields.success === false) {
      await this.$swal(
        "Oops...",
        this.selected_solicitation_validate_fields.message,
        "error"
      );
    }
    this.loading = false;
  },
  data() {
    return {
      component_loading: false,
      btn_loading: false,
      loading: true,
      valid: true,
      email_loading: true,
      loading_email_mounted: true,
      isEdited: false,
      subjectEmail: "",
      messageEmail: "",
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
    };
  },
  computed: {
    ...mapState("forms", ["wizard","form_email"]),
    ...mapState("solicitation", [
      "selected_solicitation_validate_fields",
      "solicitation_validate_values",
      "solicitation_validate_status",
      "solicitation_validate_remove_packages_values",
      "solicitation_form_email"
    ]),
    ...mapGetters("solicitation", ["getSelectedSolicitation"])
  },

  watch: {
    form_email: {
      deep: true,
      immediate: true,
      /* A cada interação com o form_email formata email para fornecedor */
      handler: async function (n) {
        if (!this.loading_email_mounted && !this.component_loading) {
          const params = {
            solicitation_id: this.$route.params.solicitation_id,
            values: n,
            custom_fields: this.solicitation_validate_values,
            create_solicitaion: false
          }
          await this.loadFormEmail(params);
        }
      }
    }
  },

  methods: {
    ...mapActions("solicitation", [
      "validateSolicitation",
      "loadSolicitationValidateFields",
      "loadSolicitationFormEmail"
    ]),
    componentLoading(value) {
      this.component_loading = value;
    },
    confirmEdit() {
      if (this.isEdited) {
        this.$swal({
          title: 'Deseja editar a solicitação?',
          text: 'Você perderá as informações adicionais que anexou ao e-mail.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isEdited = false;
          }
        });
      }
    },
    async loadFormEmail(params) {
      this.email_loading = true;
      await this.loadSolicitationFormEmail(params);
      if (this.solicitation_form_email.success && this.solicitation_form_email.data) {
        this.subjectEmail = this.solicitation_form_email.data.subjectEmail;
        this.messageEmail = this.solicitation_form_email.data.messageEmail;
      }
      this.email_loading = false;
    },
    async validate() {
      if (!this.$refs.form.validate()) {
        await this.$swal('Oops...', 'Preencha todos os campos obrigatórios.', 'warning');
        return;
      }
      this.setLoading(true);
      let body = {
        solicitation_id: this.getSelectedSolicitation.id,
        fields: this.solicitation_validate_values,
        subjectEmail: this.subjectEmail,
        messageEmail: this.messageEmail
      };
      try {
        await this.validateSolicitation(body);
        await this.$swal(this.solicitation_validate_status.message, "", "success");
        await this.$router.push({
          name: "solicitation"
        });
      } catch (error) {
        await this.$swal("Oops...", error.message, "error");
      }
      this.setLoading(false);
    },
    setLoading(value) {
      this.loading = value;
      this.btn_loading = value;
    }
  }
};
</script>
<style scoped>
.disabled-click {
  pointer-events: none;
}
</style>
