<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto">
          <v-card-title>
            <h3><v-icon class="mr-3" color="primary">mdi-folder-pound</v-icon>Solicitações</h3>
            <v-btn class="ml-3" color="primary" :to="{ name: 'solicitation-tag' }">
              Criar solicitação
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="updateEmails" :loading="updatingEmails">
              <v-icon>mdi-envelope</v-icon>
              Atualizar solicitações
            </v-btn>
            <export-solicitation-component class="ml-2" :filter="filterToExcel" :columns="user_columns" :file-name="exportFileName" :disabled="loadingSolicitations"></export-solicitation-component>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              @keyup.enter="searchSolicitation"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <template>
      <v-data-table
        class="elevation-1 custom-table"
        :loading="loadingSolicitations"
        :headers="columns"
        :items="filteredData"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        :server-items-length="totalItems"
        locale="pt"
        @update:page="onPageChange"
        @update:items-per-page="onItemsPerPageChange"
        @update:sort-by="onSortChange"
        @update:sort-desc="onSortDescChange"
      >
        <template
          v-for="(header, i) in columns"
          v-slot:[`header.${header.value}`]="{ }"
        >
          <v-row align="center" :key="i" justify="center" style="flex-wrap: inherit;">
            <v-menu v-if="header.value !== 'actions'" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn
                  :color="isFilterActive(header.value) ? 'success' : 'default'"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>

              <v-card nudge-right="50" nudge-bottom="50">
                <v-card-text>
                  <div v-if="header.value === 'created_at_format' || header.value === 'sla_deadline_finish_format' || header.value === 'updated_at_format' || header.value === 'sla_deadline_validate_format'" >
                    <v-col>
                      <v-date-picker
                        selected-items-text=""
                        v-model="multiSearch[header.value]"
                        @change="searchSolicitation"
                      ></v-date-picker>
                    </v-col>
                    <v-col>
                      <v-btn width="100%" @click="clearDate(header.value)">
                        Limpar Filtro
                      </v-btn>
                    </v-col>
                  </div>

                  <v-col v-else-if="header.value === 'status.name'">
                    <v-autocomplete
                      chips
                      deletable-chips
                      multiple
                      small-chips
                      label="Selecione"
                      v-model="multiSearch[header.value]"
                      :items="list_status"
                      item-text="display_name"
                      item-value="id"
                      @change="searchSolicitation"
                    ></v-autocomplete>
                  </v-col>

                  <v-col v-else-if="header.value === 'flag_list'">
                    <v-autocomplete
                      chips
                      deletable-chips
                      multiple
                      small-chips
                      label="Selecione"
                      v-model="multiSearch[header.value]"
                      :items="flag_list"
                      item-text="name"
                      item-value="id"
                      @change="searchSolicitation"
                    ></v-autocomplete>
                  </v-col>

                  <v-col v-else-if="header.value === 'created_by_phone'">
                    <v-autocomplete
                      dense
                      clearable
                      label="Selecione"
                      v-model="multiSearch[header.value]"
                      :items="created_by_phone_list"
                      item-text="name"
                      item-value="value"
                      @change="searchSolicitation"
                    ></v-autocomplete>
                  </v-col>

                  <v-col v-else-if="header.value !== 'actions'">
                    <v-text-field
                      :label="header.text"
                      v-model="multiSearch[header.value]"
                      class="pa"
                      type="text"
                      @keyup.enter="searchSolicitation"
                    ></v-text-field>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-menu>
            {{ header.text }}

            <v-col v-if="header.value === 'actions'">
              <v-icon
                class="button"
                @click="clearFilter"
              >
                mdi-close
              </v-icon>
              <v-menu
                v-model="menuOpen"
                transition="slide-y-transition"
                :close-on-content-click="false"
                :nudge-width="200"
                :max-height="600"
                :max-width="400"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <SolicitationFilterColumnsComponent
                  :availableColumns="columns_available"
                  :columnsSelected="user_columns"
                  @change="changeColumns"
                ></SolicitationFilterColumnsComponent>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.flag_list="{ item }">
          <div v-for="(value, index) in item.flag_list" :key="index">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-chip :color="value.color" class="d-flex justify-center" small v-bind="attrs" v-on="on">
                  {{ value.name.length > 15 ? value.name.slice(0, 15) + '...' : value.name }}
                </v-chip>
              </template>
              <span>{{ value.name }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.status.name="{ item }">
          <v-chip :color="getColor(item.status.name)" class="d-flex justify-center">
            {{ item.status.display_name }}
          </v-chip>
        </template>
        <template v-slot:item.created_by_phone="{ item }">
          <span v-if="item.created_by_phone === true">Sim</span>
          <span v-else>Não</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-center">
            <div class="mr-2" v-if="item.validable">
              <v-tooltip left >
                <template v-slot:activator="{ on }">
                  <v-icon @click="validate(item.id)" v-on="on">
                    mdi-format-list-checks
                  </v-icon>
                </template>
                <span>Validar solicitação</span>
              </v-tooltip>
            </div>

            <div v-if="item.approvable" class="mr-2">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-icon @click="approval(item.id)" v-on="on">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </template>
                <span>Aprovar solicitação</span>
              </v-tooltip>
            </div>

            <div v-if="item.is_requester" class="mr-2">
              <v-tooltip left >
                <template v-slot:activator="{ on }">
                  <v-icon @click="showDialog(item.id)" v-on="on">
                    mdi-cancel
                  </v-icon>
                </template>
                <span>Cancelar solicitação</span>
              </v-tooltip>
            </div>

            <v-tooltip left class="justify-center">
              <template v-slot:activator="{ on }">
                <v-icon @click="interacoes(item.id)" v-on="on">
                  mdi-tooltip-text-outline
                </v-icon>
              </template>
              <span>Interações</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </template>

    <solicitation-cancel-dialog
        :justifyDialog="justifyDialog"
        :loading="loadingDialog"
        @closeDialog="closeDialog"
        @save="saveDialog($event)"
    />
  </v-container>
</template>
<script>
import axios from "../../axios.config.js";
import { mapState, mapActions } from "vuex";
import SolicitationCancelDialog from "./SolicitationCancelDialog.vue";
import ExportSolicitationComponent from "../../components/solicitation/ExportSolicitationComponent.vue";
import SolicitationFilterColumnsComponent from "@/components/solicitation/SolicitationFilterColumnsComponent.vue";
export default {
  components: { SolicitationFilterColumnsComponent, ExportSolicitationComponent, SolicitationCancelDialog},
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
      multiSearch: {},
      list_status: [],
      filterToExcel: {},
      columnsUser: [],
      columns: [],
      user_columns: [],
      created_by_phone_list: [
        {
          name: 'Sim',
          value: true,
        },
        {
          name: 'Não',
          value: false,
        },
      ],
      column_values: { text: "", value: "actions", sortable: false, align: "center" },
      loadingSolicitations: true,
      search: "",
      loading: false,
      loadingDialog: false,
      menuOpen: false,
      justify: "",
      justifyDialog: false,
      solicitationId: "",
      filterModal: {},
      updatingEmails: false,
      exportFileName: 'dados_personalizados.xlsx',
      sortBy: "",
      sortOrder: "",
    };
  },
  async mounted() {
    await Promise.all([
      this.searchSolicitation(),
      this.loadFlags(),
      this.loadUserColumnsSolicitation(),
    ]);
    this.setMountedColumnsUser();
    this.loadingSolicitations = false;
  },
  computed: {
    ...mapState("solicitation", ["solicitation","status_list","total_items","total_pages","columns_available"]),
    ...mapState("rules", ["flag_list"]),
    ...mapState("index", ["errorMessage"]),
    ...mapState("user", ["user_columns_solicitation"]),

    filteredData() {
      return this.rows;
    },

    // Computed para realizar tradução de valores
    rows() {
      if (this.loadingSolicitations) {
        return [];
      }
      // return this.solicitation.data;
      return !Array.isArray(this.solicitation.data)
        ? []
        : this.solicitation.data?.map((row) => {

        // Traduz verbo do infinitivo para o particípio
        let status_label = row.status_label;
        switch (String(status_label).toLowerCase()) {
          case 'finalizar':
            status_label = 'Finalizado'
            break;
          case 'cancelar':
            status_label = 'Cancelado'
            break;
        }

        return { ...row, status_label }
      })
    }
  },
  methods: {
    ...mapActions("solicitation", ["loadSolicitation"]),
    ...mapActions("user", ["loadUserColumnsSolicitation"]),
    ...mapActions("rules", ['loadFlags']),

    isFilterActive(column) {
      const filter = this.multiSearch[column];
      return filter && (Array.isArray(filter) ? filter.length > 0 : !!filter);
    },

    setMountedColumnsUser() {
      this.user_columns = JSON.parse(JSON.stringify(this.user_columns_solicitation));
      this.columns = JSON.parse(JSON.stringify(this.user_columns_solicitation));
      this.columns.push(this.column_values);
    },

    changeColumns(data) {
      this.user_columns = JSON.parse(JSON.stringify(data));
      this.columns = JSON.parse(JSON.stringify(data));
      this.columns.push(this.column_values);
      this.menuOpen = false;
    },
    interacoes(id_solicitacao) {
      this.$router.push({
        name: "interactions",
        params: { id: id_solicitacao }
      });
    },
    onSortChange(sortBy) {
      this.sortBy = sortBy;
    },
    onSortDescChange(sortDesc) {
      this.sortOrder = sortDesc[0] === true ? 'desc' : 'asc';
      this.searchSolicitation();
    },
    validate(id) {
      this.$router.push("/validate/" + id);
    },
    approval(id) {
      this.$router.push("/approval/" + id);
    },
    clearFilter() {
      this.multiSearch = {};
      this.searchSolicitation();
    },
    clearDate(filterKey) {
      this.multiSearch[filterKey] = null;
      this.searchSolicitation();
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.searchSolicitation();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.searchSolicitation();
    },
    async updateEmails() {
      this.updatingEmails = true;
      this.loadingSolicitations = true;
      await Promise.all([
        this.loadUserColumnsSolicitation(),
        this.searchSolicitation()
      ]);
      this.setMountedColumnsUser();
      this.updatingEmails = false;
    },
    getColor(status) {
      switch (status) {
        case "aguarda_aprovacao":
          return "#9ae6b4";
        case "aguarda_validacao":
          return "#f6ad55";
        case "em_atendimento":
          return "#b794f4";
        case "envio_fornecedor":
          return "#9decf9";
        case "resposta_fornecedor":
          return "#329cc7";
        case "Cancelar":
          return "#b7c4c7";
        case "validada":
          return "#68d391";
        case "Finalizar":
          return "#acafac";
        case "em_processamento":
          return "#f1d98b";
        case "finalizar_erro":
          return "#e0a3a3";
        case "aprovada":
          return "#80a7ec";
        default:
          return "white";
      }
    },
    showDialog (id) {
      this.justifyDialog = true;
      this.solicitationId = id;
    },
    closeDialog () {
      this.justifyDialog = false;
    },
    async saveDialog(value) {
      this.loadingDialog = true;

      if (value.trim() === "") {
        await this.$swal("Oops...", "Favor digite uma justificativa.", "warning");
        this.loadingDialog = false;
        return;
      }

      const params = {
        status_id: "c5bcd554-b188-4f76-9bc7-e5a105a6b79f",
        fields: {
          descricao: value,
        },
        solicitation_id: this.solicitationId,
        visibility: "public",
      };

      const { data } = await axios.post("/interaction/", params);
      if (data.success === false) {
        await this.$swal("Oops...", data.message, "warning");
        this.loadingDialog = false;
        return;
      }
      await this.searchSolicitation();
      await this.$swal("Solicitação cancelada com sucesso.", "", "success");
      this.loadingDialog = false;
      this.closeDialog();
    },

    async searchSolicitation () {
      this.loadingSolicitations = true;
      await this.loadSolicitation({
        page: this.page,
        per_page: this.itemsPerPage,
        search: this.search,
        search_column: this.multiSearch,
        sort_by: this.sortBy,
        sort_order: this.sortOrder,
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.totalItems = this.total_items;
      this.totalPages = this.total_pages;
      this.list_status = this.status_list;
      this.filterToExcel = {
        search: this.search,
        search_column: this.multiSearch,
        sort_by: this.sortBy,
        sort_order: this.sortOrder,
      }
      this.loadingSolicitations = false;
    }
  }
};
</script>
<style scoped>
</style>
