<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title> {{ dynamicLabel}} </v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
          >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(dddValues, index) in dddChange"
      :key="index"
      md="6"
      sm="12"
      :loading="loading"
    >
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="'Troca DDD'">
          <div class="headerClass">
            {{ dddValues.line.nr_linha ? dddValues.line.ddi + ' (' + dddValues.line.ddd + ') ' + dddValues.line.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDDDChange(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mb-n9">
          <v-row>
            <v-col cols="12">
              <!-- Linhas Autocomplete -->
              <LineComponent
                :rules="objectRule"
                :selectedLine="dddValues.line"
                @save="
                  dddValues.line = $event;
                  setValueStore(index);
                  updateSelectedLine(index, $event);
                "
              >
              </LineComponent>
              <!-- Mudança Para Autocomplete -->
              <v-select
                v-if="dddValues.line && dddValues.line.id_linha"
                :items="changeTo"
                v-model="dddValues.mudancaPara"
                label="Mudança para"
                item-text="label"
                item-value="value"
                outlined
                dense
                return-object
                :disabled="!isChangeDDD"
                :rules="objectRule"
                @change="setValueStore(index); dddValues.iccid = {}"
              ></v-select>
              <!-- DDD Autocomplete -->
              <v-autocomplete
                v-if="dddValues.line && dddValues.line.id_linha"
                dense
                outlined
                required
                label="Novo DDD"
                v-model="dddValues.ddd"
                :items="ddd[index]"
                item-text="code"
                item-value="code"
                return-object
                :loading="loadingDDDs[index].value"
                :disabled="!isChangeDDD"
                :rules="[v => !!v || 'Obrigatório']"
                @input="setValueStore(index)"
              >
                <template v-slot:selection="data">
                  {{ data.item.code }} - {{ data.item.region }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                        v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.code"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.region"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <iccid-field
                v-if="dddValues.line && dddValues.line.id_linha && showFieldIccid && isChangeDDD"
                :ddd="dddValues.ddd.code"
                :chipSelected="dddValues.iccid"
                :rules="objectRule"
                @changeData="saveField($event, index, 'iccid')"
              ></iccid-field>

              <account-field
                v-if="dddValues.line && dddValues.line.id_linha && showFieldAccount && isChangeDDD"
                :rules="objectRule"
                :selectedAccount="dddValues.conta"
                @saveAccount="saveField($event, index, 'conta')"
              ></account-field>

              <LineReference
                v-if="dddValues.line && dddValues.line.id_linha && showFieldAccount && isChangeDDD"
                :selectedLine="dddValues.linha_referencia"
                @save="saveField($event, index, 'linha_referencia')"
              ></LineReference>

              <v-checkbox
                v-if="dddValues.line && dddValues.line.id_linha && showFieldPerson && isChangeDDD"
                :label="'Deseja vincular a uma nova pessoa?'"
                v-model="dddValues.person.checkbox"
                class="mt-0"
                dense
                @click="checkBoxChange(index)"
              ></v-checkbox>

              <person-field
                v-if="dddValues.line && dddValues.line.id_linha && showFieldPerson && dddValues.person.checkbox === true && isChangeDDD"
                :label="'Nova Pessoa'"
                :rules="objectRule"
                :selectedPerson="dddValues.person"
                @save="saveField($event, index, 'person')"
              ></person-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Pacotes da linha -->
        <v-card-text class="align-start">
          <v-alert
            border="left"
            class="text-center"
            dense
            type="warning"
            v-if="loadingLinePackages[index].value"
            dark
          >Carregando pacotes da linha!
          </v-alert>

          <template v-if="dddValues.packages && dddValues.packages.data && !loading && !loadingLinePackages[index].value">
            <v-alert border="left" color="indigo" dark denseclass="mb-0">
              Pacotes da linha:
            </v-alert>
            <v-list>
              <template v-for="(linePackage, index2) in dddValues.packages.data">
                <v-list-item :key="linePackage.nome">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ linePackage.nome }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index2 < dddValues.packages.data.length - 1"
                  :key="index2"
                ></v-divider>
              </template>

              <template v-if="dddValues.packages.data == ''">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Não possui
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import IccidField  from "@/components/fields/IccidField.vue";
import AccountField from "@/components/fields/AccountField.vue";
import PersonField from "@/components/fields/PersonField.vue";
import LineReference from "@/components/fields/LineReference.vue";
import LineComponent from "@/components/forms/LineComponent.vue";
import isEmpty from "lodash/isEmpty";

export default {
  props: {
    index: {},
    groupUser: {},
    uniqueKeyStorage: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    }
  },
  components: {
    LineComponent,
    LineReference,
    PersonField,
    AccountField,
    IccidField,
  },
  data() {
    return {
      loading: true,
      objectRule: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      packagesSelect: [],
      show: false,
      dynamicLabel: "",
      isChangeDDD: false,
      showFieldIccid: false,
      showFieldAccount: false,
      showFieldPerson: false,
      searchDDD: "",
      chipSelected: {},
      loadingLinePackages: [{
        value: false
      }],
      loadingDDDs: [{
        value: false
      }],
      dddChange: [{
        change_to: "",
        mudancaPara: {},
        line: {},
        ddd: "",
        packages: {},
        conta: {},
        linha_referencia: {},
        iccid: {},
        person: {
          checkbox: false,
        },
        new_line: {
          name: 'new_line'
        }
      }]
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.loadChangeTo(),
        this.loadLinePackages()
      ]);
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }

    if (this.wizard.forms && this.wizard.forms[this.index].label) {
      this.isChangeDDD = this.wizard.forms[this.index].type === 'troca_ddd' ?? true;
      this.dynamicLabel = this.wizard.forms[this.index].label;
    }

    if (this.groupUser === 1 || this.groupUser === 2) {
      this.showFieldIccid = true;
      this.showFieldAccount = true;
      this.showFieldPerson = true;
    }

    const dataLocalStorage = await this.loadDataLocalStorage(this.uniqueKeyStorage);
    if (!isEmpty(dataLocalStorage) && this.page === 'wizard') {
      this.resultStorage = dataLocalStorage;
      this.dddChange = Object.assign([], this.resultStorage);
      this.dddChange.forEach(value => {
        this.loadingLinePackages.push({ value: false });
        this.loadingDDDs.push({ value: false });
      });
    }
    this.loading = false;
  },
  computed: {
    ...mapState("ddd", ["ddd", "changeTo"]),
    ...mapState("packages", ["lines"]),
    ...mapState("forms", ["wizard"]),
    ...mapGetters({
      label: "forms/getItemLabel"
    }),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("ddd", ["loadDDDByRegion", "loadChangeTo"]),
    ...mapActions("packages", ["loadLinePackages"]),
    ...mapActions("solicitation", ["loadDataLocalStorage"]),
    ...mapMutations({
      setModel: "forms/setModelDDDChange"
    }),

    checkBoxChange(index) {
      if (!this.dddChange[index].person.checkbox) {
        this.dddChange[index].person = {checkbox: false};
      }
    },

    saveField(data, index, field) {
      this.dddChange[index][field] = data;
      if (field === 'person') {
        this.dddChange[index][field]['checkbox'] = true;
      }
      this.setValueStore(index);
    },

    async clickAddNewLine() {
      this.loadingLinePackages = [...this.loadingLinePackages, {value: false}];
      this.loadingDDDs = [...this.loadingDDDs, {value: false}];
      const newLine = {
        change_to: "",
        mudancaPara: {},
        line: {},
        ddd: "",
        packages: {},
        conta: {},
        linha_referencia: {},
        iccid: {},
        person: {
          checkbox: false,
        },
        new_line: {
          name: 'new_line'
        }
      };
      this.dddChange = [...this.dddChange, newLine];
    },
    closeDDDChange(index) {
      if (index > -1) {
        this.dddChange.splice(index, 1);
        this.loadingDDDs.splice(index, 1);
        let payload = {
          step: this.index,
          params: {value: this.dddChange}
        };
        this.setModel(payload);
      }
    },
    async setValueStore(index) {
      if (!this.isChangeDDD) {
        this.dddChange[index].change_to = 1;
        this.dddChange[index].mudancaPara = {
          label: "Mesma Região",
          value: "1",
        };
      }else {
        this.dddChange[index].change_to = this.dddChange[index].mudancaPara.value ? this.dddChange[index].mudancaPara.value : "";
      }
      let payload = {
        step: this.index,
        params: {value: this.dddChange}
      };
      this.setModel(payload);

      if (this.dddChange[index].mudancaPara && this.dddChange[index].mudancaPara.value) {
        this.loadingDDDs[index].value = true;
        const params = {
          index: index,
          ddd: this.dddChange[index].line.ddd,
          mudancaPara: this.dddChange[index].mudancaPara.value
        };
        await this.loadDDDByRegion(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });

        if (!this.isChangeDDD) {
          this.dddChange[index].ddd = this.ddd[0].find(dddItem => dddItem.code === this.dddChange[index].line.ddd);
        }
        this.loadingDDDs[index].value = false;
      }
      if (this.page === 'wizard') {
        localStorage.setItem(this.uniqueKeyStorage, JSON.stringify(this.dddChange));
      }
    },
    async updateSelectedLine(index, line) {
      // this.show = this.show ? false : this.linePackages;
      this.loadingLinePackages[index].value = true;
      if (line.id_linha) {
        await this.loadLinePackages({ id_linha: line.id_linha }).catch(
          error => {
            this.$swal("Oops...", error.message, "error");
          }
        );
      }

      this.dddChange[index].packages = this.linePackages;
      if (!this.isChangeDDD) {
        this.dddChange[index].change_to = 1;
      }
      let payload = {
        step: this.index,
        params: {value: this.dddChange}
      };
      this.setModel(payload);
      this.show = false;
      this.loadingLinePackages[index].value = false;
      if (this.page === 'wizard') {
        localStorage.setItem(this.uniqueKeyStorage, JSON.stringify(this.dddChange));
      }
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  },
};
</script>
<style scoped></style>
