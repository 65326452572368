<template>
  <v-row class="mx-auto">
    <v-col cols="12" v-if="titlePage">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title> {{ titlePage }} </v-card-title>
      </v-card>
    </v-col>
    <v-col
      v-for="(dddChange, index) in dddChangeSelect[0]"
      :key="index"
      md="6"
      sm="12"
    >
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="'Troca DDD'">
          <div class="headerClass">
            {{ dddChange.line.nr_linha ? dddChange.line.ddi + ' (' + dddChange.line.ddd + ') ' + dddChange.line.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="mb-n9">
          <v-row>
            <v-col cols="12">
              <div v-if="loading">
                <v-skeleton-loader type="heading"></v-skeleton-loader>
              </div>
              <!-- Linha -->
              <LineComponent
                v-show="!loading"
                :selectedLine="dddChange.line"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                @save="
                  dddChange.line = $event;
                  updatePackagesFromLine($event, dddChange.line);
                  saveSelected(dddChange, index);
                "
              ></LineComponent>
              <!-- DDD -->
              <v-autocomplete
                dense
                outlined
                label="DDD"
                v-model="dddChange.ddd"
                :items="listDDD"
                item-text="code"
                item-value="code"
                :disabled="!isChangeDDD"
                return-object
                v-if="!loading"
                @change="saveSelected(dddChange, index); fetchChips(dddChange.ddd.code, index)"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
              >
                <template v-slot:selection="data">
                  {{ data.item.code }} - {{ data.item.region }}
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                        v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                          v-html="data.item.code"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                          v-html="data.item.region"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <!-- ICCID -->
              <v-combobox
                v-if="!loading && !desactiveComponentNewIccid && page === 'validate' && isChangeDDD"
                label="Buscar ou criar novo ICCID"
                v-model="dddChange.iccid"
                item-text="iccid"
                item-value="iccid"
                :rules="rulesIccid"
                :items="iccids[index]"
                outlined
                dense
                :search-input.sync="searchInputs[index]"
                :loading="loading"
                return-object
                @change="saveSelected(dddChange, index)"
                :filter="filterIccid"
                @keydown="onKeyDown($event, index)">
                <template #no-data>
                  <v-list-item v-if="searchInputs[index] != null && searchInputs[index].length > 0" @click="createNewIccid(index, searchInputs[index])">
                    <span class="subheading mr-2">Criar ICCID</span>{{ searchInputs[index] }}
                  </v-list-item>
                </template>

                <template #selection="data">
                  <template v-if="dddChange.iccid != null && Object.keys(dddChange.iccid).length > 0 && data.item.ddd && data.item.iccid">
                    (DDD - {{ data.item.ddd }}) {{ data.item.iccid }}
                  </template>
                  <template v-if="dddChange.iccid != null && Object.keys(dddChange.iccid).length > 0 && !data.item.ddd && data.item.iccid">
                    (HIBRIDO) {{ data.item.iccid }}
                  </template>
                </template>

                <template #item="data">
                  <v-list-item-content v-if="data.item.ddd">
                    <v-list-item-title>(DDD - {{ data.item.ddd }}) {{ data.item.iccid }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-if="!data.item.ddd">
                    <v-list-item-title>(HIBRIDO) {{ data.item.iccid }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-combobox>

              <!-- Conta -->
              <v-autocomplete
                v-model="dddChange.conta"
                :items="listConta"
                item-text="cod_conta"
                item-value="value"
                outlined
                dense
                return-object
                label="Conta"
                v-if="!loading && isChangeDDD"
                @change="saveSelected(dddChange, index)"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
              >
                <template v-slot:selection="data">
                  <template>
                    (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
                  </template>
                </template>

                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.descricao }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <LineReference
                v-if="!loading && isChangeDDD"
                :selectedLine="dddChange.linha_referencia"
                @save="saveFieldLinhaReferencia($event, index)"
              ></LineReference>

              <v-checkbox
                :label="'Deseja vincular a uma nova pessoa?'"
                v-model="dddChange.person.checkbox"
                class="mt-0"
                dense
                v-if="!loading && isChangeDDD"
                @change="saveSelected(dddChange, index)"
              ></v-checkbox>
              <!-- Nova Pessoa -->
              <PersonField
                v-if="!loading && dddChange.person.checkbox && isChangeDDD"
                :label="'Nova Pessoa'"
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                :selectedPerson="dddChange.person"
                @save="updateNewPerson($event, index); dddChange.person.checkbox = true; "
              ></PersonField>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Pacotes da linha -->
        <v-card-text
          class="align-start"
          v-if="dddChange.line"
        >
          <v-alert
            border="left"
            class="text-center"
            dense
            type="warning"
            v-if="loadingPackages"
            dark
            >Carregando pacotes da linha!
          </v-alert>
          <template v-if="dddChange.packages.data && !loadingPackages">
            <v-alert border="left" color="indigo" dark dense class="mb-0">
              Pacotes da linha:
            </v-alert>
            <v-list>
              <template v-for="(packageLine, index) in dddChange.packages.data">
                <v-list-item :key="packageLine.nome">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ packageLine.nome }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < dddChange.packages.data.length - 1"
                  :key="index"
                ></v-divider>
              </template>

              <template v-if="dddChange.packages.data == ''">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Não possui
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </template>
        </v-card-text>
        <custom-new-single-chip-form-component
          v-if="!desactiveComponentNewIccid"
          v-model="isChipDialogOpen[index]"
          :iccid="createIccid"
          :listIccid="iccids[indexNewIccid]"
          :index="index"
          @changeSelectedChip="updateSelectedChip($event)"
          @close="onCloseDialog($event)"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import CustomNewSingleChipFormComponent from "../forms/CustomChangeChipComponent/CustomNewSingleChipFormComponent.vue";
import LineReference from "@/components/fields/LineReference.vue";
import axios from "@/axios.config";
import LineComponent from "@/components/forms/LineComponent.vue";
import PersonField from "@/components/fields/PersonField.vue";
export default {
  components: { PersonField, LineComponent, LineReference, CustomNewSingleChipFormComponent},
  props: ["field", "page", "titlePage"],
  data() {
    return {
      dddChangeSelect: [],
      loading: true,
      loadingPackages: false,
      desactiveComponentNewIccid: false,
      isChipDialogOpen: [],
      linePackage: [],
      isChangeDDD: false,
      createIccid: '',
      iccids: [],
      indexNewIccid: '',
      selectedChip: [],
      rulesIccid: [],
      search: '',
      searchInputs: [],
      listDDD: [],
      listConta: [],
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  async created() {
    //Zera os dados de DDD do store
    await this.updateSolicitationValidateDDDValues({})
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.dddChangeSelect = this.field.data;
    await Promise.all([
      this.loadPersons(),
    ]).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    for (let i in this.field.data[0]) {
      let ddd = this.field.data[0][i]['ddd'].code;
      await this.fetchChips(ddd, i);
      this.selectedChip[i] = this.field.data[0][i]['iccid'].iccid ? this.field.data[0][i]['iccid'] : {};
      await this.saveSelected(this.field.data[0][i]);
    }
    this.isChangeDDD = this.field.type === 'troca_ddd' ?? true;
    this.listDDD = this.field.data[0][0].ddd.options;
    this.listConta = this.field.data[0][0].conta.options;

    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages"
    }),
    ...mapState("persons", ["persons"]),
    ...mapState("solicitation", ["solicitation_validate_values"])
  },
  methods: {
    ...mapActions("solicitation", ["updateSolicitationValidateDDDValues","setAutomationComponentValues"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("packages", [
      "loadingFilters",
      "loadPackages",
      "loadLinePackages"
    ]),
    ...mapActions("persons", ["loadPersons"]),
    async updatePackagesFromLine(line, field) {
      this.loadingPackages = true;
      if (line.id_linha) {
        await this.loadLinePackages({ id_linha: line.id_linha }).catch(
          error => {
            this.$swal("Oops...", error.message, "error");
          }
        );
        field.packages = this.linePackages;
        this.linePackage = this.linePackages;
        await this.saveSelected(field);
      }
      this.loadingPackages = false;
    },

    updateNewPerson(data, index) {
      this.dddChangeSelect[0][index].person = data;
      this.saveSelected();
    },

    fetchChips: async function (ddd, index) {
      this.loading = true
      await axios.get('solicitation/chips/available', {
        params: {ddd}
      })
          .then((res) => {

            if (res.data.success) {
              this.iccids[index] = res.data.data
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
    saveFieldLinhaReferencia(event, index) {
      this.field.data[0][index].linha_referencia = event;
      this.saveSelected();
    },
    async saveSelected() {
      let values_fields = {...this.solicitation_validate_values};
      values_fields['troca_ddd'] = {
        values: this.field.data[0],
      }
      this.setAutomationComponentValues({
        automation: 'troca_ddd',
        data: values_fields
      });
      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
    },
    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    updateSelectedChip: function (event) {
      this.loading = true;
      if (!event.new_chip) {
        this.$swal(
            "Oops..", "Ocorreu um erro ao salvar novo ICCID",
            "error"
        );
        return;
      }
      this.dddChangeSelect[0][event.index].iccid.value = event.new_chip.iccid;
      this.selectedChip[event.index] = event.new_chip;
      this.loading = false;
    },
    onCloseDialog: function (event) {
      this.isChipDialogOpen[event.index] = false;
      this.createIccid = undefined
      this.searchInputs[event.index] = undefined
    },
    saveSelectedChip: function (index) {
      this.dddChangeSelect[0][index].iccid.value = this.selectedChip[index].iccid;
      this.saveSelected(this.dddChangeSelect[0][index]);
    },
    filterIccid: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.iccid)
    },
    onKeyDown: function (e, index) {
      /**
       * Workaround: Quando enter é pressionado componente combobox
       * joga valor do search para um campo mágico e acaba com todo o fluxo.
       * Dessa forma esvazia de forma forçada o campo de busca e povoa o campo
       * para criação de iccid
       */
      if (e.keyCode === 13 /* ENTER */) {
        this.createIccid = this.searchInputs[index]
        this.searchInputs[index] = undefined
      }
    },
    createNewIccid: function (index, iccid) {
      this.isChipDialogOpen[index] = true;
      this.createIccid = iccid;
      this.indexNewIccid = index;
    },
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
