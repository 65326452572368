<template>
  <div>
    <v-autocomplete
      v-model="lineSelected"
      dense
      outlined
      ref="autocomplete"
      item-text="nr_linha"
      :label="label"
      :items="lineList"
      return-object
      :readonly="readonly"
      :rules="rules"
      :loading="loading"
      :item-disabled="isItemDisabled"
      :filter="customFilter"
      @change="save"
    >
      <template v-slot:selection="data">
        {{ data.item.ddi }} ({{ data.item.ddd }})
        {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
        {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.ddi }} ({{ data.item.ddd }})
              {{ data.item.nr_linha }}
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.nome_completo"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:no-data>
        <template>
          <v-list-item-content style="text-align: center">
            <v-list-item-title v-if="loading">
              Carregando...
            </v-list-item-title>
            <v-list-item-title v-else>
              Não há dados disponíveis
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import auto from "chart.js/auto";

export default {
  props: {
    selectedLine: {
      type: [Object, Array],
      default: () => {},
    },
    label: {
      type: String,
      default: 'Linha'
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    suspendLines: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      lineSelected: {},
      lineList: [],
      showPackage: false,
      loading: true,
      lastQuery: "",
      params: {
        search_text: "",
        status_line: "",
      }
    };
  },
  async mounted() {
    try {
      if (this.suspendLines === true) {
        this.params.status_line = 'suspend';
      }
      if (this.selectedLine && Object.keys(this.selectedLine).length > 0) {
        this.lineSelected = this.selectedLine;
      }
      if (this.readonly) {
        this.lineList = [this.lineSelected];
      } else {
        await this.loadLines(this.params);
        this.lineList = this.lines.data;
        if (this.selectedLine && Object.keys(this.selectedLine).length > 0) {
          this.lineList = [...this.lineList, this.selectedLine];
        }
      }
      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("packages", ["lines"]),
  },
  methods: {
    ...mapActions("packages", ["loadLines"]),

    async fetchLines(query) {
      this.loading = true;
      try {
        this.params.search_text = query.toLowerCase().replace(/[\s()\-/]/g, '');
        await this.loadLines(this.params);
        if (this.lines.data.length > 0) {
          this.lineList = this.lines.data;
        } else {
          this.lineList = [{ nr_linha: "Nenhuma linha encontrada", ddi: '', cod_conta: '', id_linha: null, nome_completo: '' }];
        }
        this.lastQuery = query;
      } catch (error) {
        await this.$swal("Oops...", 'Erro ao buscar linhas', "error");
        this.lineList = [];
      }
      this.loading = false;
    },

    filter: function(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
        regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },

    customFilter(item, queryText) {
      // Se o query atual não é igual ao último valor buscado, chama o backend
      if (queryText !== this.lastQuery && queryText.length >= 3) {
        this.debouncedFetch(queryText);
      }

      queryText = queryText.toLowerCase().replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
        regExp.test(phoneNumber) || regExp.test(item.nome_completo.toLowerCase().replace(/\s/g, ''))
      );
    },

    save(value) {
      if (!this.readonly) {
        if (!value) value = {};
        this.$emit("save", value);
      }
    },

    isItemDisabled(item) {
      return item.id_linha === null;
    },
  },
  watch: {
    selectedLine(newVal) {
      if (this.lineSelected !== newVal) {
        this.lineSelected = newVal;
      }
    },
  },
  created() {
    this.debouncedFetch = debounce(this.fetchLines, 500);
  },
};
</script>
<style scoped></style>
