<template>
  <v-btn @click="exportToExcel" :disabled="disabled" color="primary">Exportar para Excel</v-btn>
</template>

<script>
import * as XLSX from 'xlsx';
import { mapActions, mapState } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    fileName: {
      type: String,
      default: 'dados.xlsx',
    },
    sheetName: {
      type: String,
      default: 'Sheet1',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState("solicitation", ["dataExcel"]),
  },
  methods: {
    ...mapActions("solicitation", ["loadSolicitationExcel"]),
    getNestedValue(obj, path) {
      return path.split('.').reduce((value, key) => value[key], obj);
    },
    async exportToExcel() {
      try {
        await this.loadSolicitationExcel({
          page: 1,
          per_page: -1,
          search: this.filter.search,
          search_column: this.filter.search_column,
          sort_by: this.sortBy,
          sort_order: this.sortOrder,
        }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });

        const data = this.dataExcel;
        let columnsExport = this.columns = JSON.parse(JSON.stringify(this.columns));
        columnsExport.push({ text: "Info Solicitação", value: "info_solicitation" })

        // Filtra e formata os dados para exportação
        const dadosFiltrados = data.map(objeto => {
          const novoObjeto = {};
          this.columns.forEach(coluna => {
            novoObjeto[coluna.text] = this.getNestedValue(objeto, coluna.value); // Usa 'text' como chave e o valor como conteúdo
          });
          return novoObjeto;
        });

        // Gera o nome do arquivo com a data atual
        const dataFormatada = new Date().toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const nomeArquivo = `${dataFormatada}_${this.fileName}`;

        // Cria a planilha Excel
        const ws = XLSX.utils.json_to_sheet(dadosFiltrados);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
        XLSX.writeFile(wb, nomeArquivo);
      } catch (error) {
        await this.$swal("Oops...", error.message, "error");
      }
    },
  },
};
</script>