<template>
  <div>
  <v-row :class="setClass">
    <v-col :cols="setCols">
      <v-autocomplete
        v-model="search"
        :items="users.data"
        item-text="nome"
        item-value="id_join"
        outlined
        dense
        clearable
        :label="labelField"
        :search-input.sync="searchUser"
        return-object
        @change="saveSelected"
        :loading="loading"
        :disabled="loading"
        :item-disabled="isItemDisabled"
        :filter="filterObject"
        :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório', v => (v && v.id_join && v.id_join !== '') || 'Obrigatório']"
      >
        <template v-slot:no-data>
          <template>
            <v-list-item-content style="text-align: center">
              <v-list-item-title v-if="loadingSearch">
                Carregando...
              </v-list-item-title>
              <v-list-item-title v-else>
                <v-list-item v-if="permissionAdmSystem && searchUser != null && searchUser.length > 0 && !loadingSearch" @click="createNewUser(searchUser)">
                  <span class="subheading mr-2">Criar usuário</span>{{ searchUser }}
                </v-list-item>
                <v-list-item v-else>
                  <span class="subheading mr-2">Nenhum usuário encontrado</span>
                </v-list-item>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
  <custom-dialog-new-user-component
    v-if="permissionAdmSystem && showDialogNewUser"
    v-model="showDialogNewUser"
    :newUser="newUser"
    :listUsers="users.data"
    @close="showDialogNewUser = !showDialogNewUser"
    @newUserData="newUserData"
  ></custom-dialog-new-user-component>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import CustomDialogNewUserComponent from "@/components/forms/CustomRequesterComponent/CustomDialogNewUserComponent.vue";
import isEmpty from "lodash/isEmpty";
import { debounce } from "lodash";

export default {
  components: { CustomDialogNewUserComponent },
  props: {
    setClass: {
      default: "mx-auto",
    },
    setCols: {
      default: "5",
    },
    page: {},
    index: {},
    field: {},
    labelField: {
      type: String,
      default: "Solicitante"
    },
    groupUser: {},
    emailRequester: {
      type: String,
      default: '',
    },
    selectedRequester: {}
  },
  data() {
    return {
      search: {},
      searchUser: '',
      loading: true,
      showDialogNewUser: false,
      newUser: '',
      listData: [],
      permissionAdmSystem: false,
      loadingSearch: false,
      debounceFetchMoreUsers: null,
      lastSearchQuery: '',
      params: {
        search: ''
      }
    };
  },
  async mounted() {
    await this.loadUsers().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    if (!isEmpty(this.emailRequester)) {
      this.users.data.forEach(user => {
        if (this.emailRequester === user.email) {
          this.search = user;
          this.saveSelected();
          return true;
        }
      })
    }

    if (this.groupUser && this.groupUser === 1) {
      this.permissionAdmSystem = true;
    }

    if (this.page === 'validate') {
      this.users.data.push({id_join: this.selectedRequester.value, nome: this.selectedRequester.text});
      this.search = {id_join: this.selectedRequester.value, nome: this.selectedRequester.text};
    }

    this.loading = false;
  },
  computed: {
    ...mapState("user", ["users"]),
    ...mapState("solicitation", ["solicitation_validate_values"]),
    ...mapGetters({
      label: "forms/getItemLabel",
      value: "forms/getItemValue"
    })
  },
  methods: {
    ...mapActions("user", ["loadUsers"]),
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),
    ...mapMutations({
      setModel: "forms/setModelRequester",
      SET_FORM_EMAIL: "forms/SET_FORM_EMAIL",
    }),

    newUserData(data) {
      this.search = data;
      this.saveSelected();
    },

    createNewUser(user) {
      this.showDialogNewUser = true;
      this.newUser = user;
      this.searchUser = '';
    },

    saveSelected() {
      if (this.page === 'wizard') {
        this.setModel({
          index: this.index,
          id: this.search.id_join,
          usuario: this.search.nome
        });
      } else if (this.page === 'validate') {
        this.updateSolicitationValidateValues({
          text: this.search.nome ? this.search.nome : "",
          value: this.search.id_join ? this.search.id_join : "",
          model: 'requester'
        });
        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      }
    },

    isItemDisabled(item) {
      return item.id_join === null;
    },

    filterObject(item, queryText) {
      this.loadingSearch = true;
      const text = this.clearText(item.nome);
      const searchText = this.clearText(queryText);

      const matches = text.indexOf(searchText) > -1;
      if (!matches && this.lastSearchQuery !== searchText) {
        this.lastSearchQuery = searchText;
        if (!this.debounceFetchMoreUsers) {
          this.debounceFetchMoreUsers = debounce(this.loadUsersFilter, 500);
        }
        this.debounceFetchMoreUsers(searchText);
      }else {
        this.loadingSearch = false;
        return matches;
      }
    },

    async loadUsersFilter() {
      this.loadingSearch = true;
      try {
        this.params.search = this.lastSearchQuery;
        await this.loadUsers(this.params);
        if (this.users.data.length < 1) {
          this.users.data = [{ nome: "Nenhum usuário encontrado", id_join: null }];
        }
        this.loadingSearch = false;
      } catch (error) {
        await this.$swal("Oops...", 'Erro ao buscar linhas', "error");
        this.users.data = [{ nome: "Nenhum usuário encontrado", id_join: null }];
      }
    },

    clearText(text) {
      let cleanText = text.toLowerCase();
      cleanText = cleanText.replace(new RegExp(/\s/g), " ");
      cleanText = cleanText.replace(new RegExp(/[àáâãäå]/g), "a");
      cleanText = cleanText.replace(new RegExp(/æ/g), "ae");
      cleanText = cleanText.replace(new RegExp(/ç/g), "c");
      cleanText = cleanText.replace(new RegExp(/[èéêë]/g), "e");
      cleanText = cleanText.replace(new RegExp(/[ìíîï]/g), "i");
      cleanText = cleanText.replace(new RegExp(/ñ/g), "n");
      cleanText = cleanText.replace(new RegExp(/[òóôõö]/g), "o");
      cleanText = cleanText.replace(new RegExp(/œ/g), "oe");
      cleanText = cleanText.replace(new RegExp(/[ùúûü]/g), "u");
      cleanText = cleanText.replace(new RegExp(/[ýÿ]/g), "y");
      cleanText = cleanText.replace(new RegExp(/\W/g), " ");

      return cleanText;
    }
  },
  created() {
    if (this.page === 'wizard') {
      this.search = { id_join: this.value(this.index) };
    } else if (this.page === 'validate') {
      this.search = { id_join: this.selectedRequester.id_join };
    }
  },
};
</script>
<style scoped></style>
