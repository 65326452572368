<template>
  <div>
    <v-autocomplete
      v-model="search"
      :items="listUser"
      item-text="nome"
      item-value="id_join"
      outlined
      dense
      clearable
      :label="label"
      :search-input.sync="searchUser"
      return-object
      @change="saveSelected"
      :loading="loading"
      :disabled="loading"
      :item-disabled="isItemDisabled"
      :filter="filterObject"
      :rules="rules"
    >
      <template v-slot:no-data>
        <v-list-item-content style="text-align: center">
          <v-list-item-title>{{ noDataMessage }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  props: {
    index: {},
    selectedUser: {
      type: [Object, Array],
      default: null
    },
    label: {
      type: String,
      default: "Usuário"
    },
    fieldRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: {},
      searchUser: '',
      loading: true,
      loadingSearch: false,
      debounceFetchMoreUsers: null,
      lastSearchQuery: '',
      listUser: [],
      rules: [],
      params: {
        search: ''
      }
    };
  },
  async mounted() {
    await this.loadUsers().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.listUser = JSON.parse(JSON.stringify(this.users.data));
    if (this.selectedUser && Object.keys(this.selectedUser).length > 2) {
      this.listUser.push(this.selectedUser);
      this.search = this.selectedUser;
    }

    if (this.fieldRequired) {
      this.rules = [v => (v && Object.keys(v).length > 0) || 'Obrigatório', v => (v && v.id_join && v.id_join !== '') || 'Obrigatório'];
    }

    this.loading = false;
  },
  computed: {
    ...mapState("user", ["users"]),

    noDataMessage() {
      if (this.loadingSearch) {
        return 'Carregando...';
      }
      if (!this.users.data.length || this.users.data[0].id_join == null) {
        return 'Nenhum usuário encontrado';
      }
      return '';
    },
  },
  methods: {
    ...mapActions("user", ["loadUsers"]),

    saveSelected(data) {
      this.$emit("saveSelected", data);
    },

    isItemDisabled(item) {
      return item.id_join === null;
    },

    filterObject(item, queryText) {
      this.loadingSearch = true;
      const text = this.clearText(item.nome);
      const searchText = this.clearText(queryText);

      const matches = text.indexOf(searchText) > -1;
      if (!matches && this.lastSearchQuery !== searchText) {
        this.lastSearchQuery = searchText;
        if (!this.debounceFetchMoreUsers) {
          this.debounceFetchMoreUsers = debounce(this.loadUsersFilter, 500);
        }
        this.debounceFetchMoreUsers(searchText);
      }else {
        this.loadingSearch = false;
        return matches;
      }
    },

    async loadUsersFilter() {
      this.loadingSearch = true;
      try {
        this.params.search = this.lastSearchQuery;
        await this.loadUsers(this.params);
        this.listUser = JSON.parse(JSON.stringify(this.users.data));
        if (this.users.data.length < 1) {
          this.listUser = [{ nome: "Nenhum usuário encontrado", id_join: null }];
        }
        this.loadingSearch = false;
      } catch (error) {
        await this.$swal("Oops...", 'Erro ao buscar linhas', "error");
        this.listUser = [{ nome: "Nenhum usuário encontrado", id_join: null }];
      }
    },

    clearText(text) {
      let cleanText = text.toLowerCase();
      cleanText = cleanText.replace(new RegExp(/\s/g), " ");
      cleanText = cleanText.replace(new RegExp(/[àáâãäå]/g), "a");
      cleanText = cleanText.replace(new RegExp(/æ/g), "ae");
      cleanText = cleanText.replace(new RegExp(/ç/g), "c");
      cleanText = cleanText.replace(new RegExp(/[èéêë]/g), "e");
      cleanText = cleanText.replace(new RegExp(/[ìíîï]/g), "i");
      cleanText = cleanText.replace(new RegExp(/ñ/g), "n");
      cleanText = cleanText.replace(new RegExp(/[òóôõö]/g), "o");
      cleanText = cleanText.replace(new RegExp(/œ/g), "oe");
      cleanText = cleanText.replace(new RegExp(/[ùúûü]/g), "u");
      cleanText = cleanText.replace(new RegExp(/[ýÿ]/g), "y");
      cleanText = cleanText.replace(new RegExp(/\W/g), " ");

      return cleanText;
    }
  },
};
</script>
<style scoped></style>
