<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card :loading="loading" outlined>
          <v-card-title class="">
            <h2>Solicitação Aguarda Validação</h2>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" :to="{ name: 'solicitation' }">
              Voltar
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pb-0">
            {{ selected_solicitation.description }}
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col class="col-sm-12 col-md-8 col-lg-9">
                <ListFormsComponent v-if="!loading" :is_adm="is_adm" />
                <v-skeleton-loader
                  v-else
                  type="table-heading, article, actions"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="col-md-4 col-lg-3">
                <time-line v-if="!loading"></time-line>
                <v-skeleton-loader
                  v-else
                  type="card-heading, paragraph"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <historic v-if="!loading" :is_adm="is_adm"></historic>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import TimeLine from "@/components/interaction/interactionTimeLineComponent";
import Historic from "@/components/interaction/interactionHistoricComponent";
import ListFormsComponent from "@/components/ListFormsComponent";

export default {
  components: {
    TimeLine,
    Historic,
    ListFormsComponent
  },
  data() {
    return {
      selected_solicitation: {},
      loading: true,
      is_adm: false,
    };
  },
  async mounted() {
    try {
      await this.resetValidateSolicitationState();
      const solicitationId = this.$route.params.solicitation_id;
      const cacheKeySolicitation = `validate_${solicitationId}`;
      const cacheKeyInteraction = `validate_interaction_${solicitationId}`;
      const cachedDataSolicitations = localStorage.getItem(cacheKeySolicitation);
      const cachedDataInteractions = localStorage.getItem(cacheKeyInteraction);

      if (!cachedDataSolicitations && !cachedDataInteractions) {
        await Promise.all([
          this.loadSelectedSolicitation(solicitationId),
          this.loadInteractions({solicitation: this.$route.params.solicitation_id})
        ])
        this.is_adm = this.getSelectedSolicitation.is_adm;
        // Armazena os dados no storage
        localStorage.setItem(cacheKeySolicitation, this.getSelectedSolicitation);
        localStorage.setItem(cacheKeyInteraction, this.interactions);
      } else {
        if (cachedDataSolicitations) {
          await this.updateSelectedSolicitation({data: [JSON.parse(cachedDataSolicitations)]});
          this.selected_solicitation = cachedDataSolicitations;
          this.is_adm = this.selected_solicitation.is_adm;
        }

        if (cachedDataInteractions) {
          await this.updateInteraction({data: JSON.parse(cachedDataInteractions)});
        }
      }
    }catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.loading = false;
  },
  computed: {
    ...mapState("interaction", ["interactions"]),
    ...mapGetters("solicitation", ["getSelectedSolicitation"])
  },
  methods: {
    ...mapActions("interaction", ["loadInteractions","updateInteraction"]),
    ...mapActions("solicitation", ["loadSelectedSolicitation", "resetValidateSolicitationState","updateSelectedSolicitation"]),
  }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
