<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto" elevation="2">
          <div class="d-flex justify-end pr-4 pt-2">
            <v-icon @click="downloadPNG" class="ml-2" style="color: #4d638c;">mdi-file-png-box</v-icon>
            <v-icon v-if="showMdiWindowMax" @click="changeCols('12'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-maximize</v-icon>
            <v-icon v-else @click="changeCols('6'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-restore</v-icon>
          </div>
          <v-card-title class="mt-n5" style="display: flex; justify-content: center;">
            <h4> Solicitações por Status - ({{day}})</h4>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                  style="cursor: pointer;"
                >
                  mdi-information
                </v-icon>
              </template>

              <span>
                Esta consulta recupera o número de solicitações abertas por Status no período de ({{day}}).
              </span>
            </v-tooltip>
          </v-card-title>
          <v-col v-if="loading" class="col-md-12 d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col v-if="showErrorMessage" class="col-md-12 d-flex justify-center align-center">
            {{messageError}}
          </v-col>
          <div v-show="!loading" style="position: relative; height: 100%; width: 100%">
            <canvas ref="totalSolicitationStatus"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <SolicitationListModal
      v-if="showModal"
      :modalTitle="titleModal"
      :listItems="listModal"
      @close="showModal = false"
    ></SolicitationListModal>
  </v-container>
</template>
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions, mapState } from "vuex";
import SolicitationListModal from "@/components/solicitation/modal/SolicitationListModal.vue";

export default {
  components: { SolicitationListModal },
  props: {
    selectedDate: {
      type: Object,
    },
    day: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: true,
      messageError: '',
      chartInstance: null,
      showErrorMessage: false,
      showModal: false,
      listModal: [],
      titleModal: '',
      showMdiWindowMax: true,
    };
  },
  async mounted() {},
  computed: {
    ...mapState("dashboard", ["totalStatus"])
  },
  methods: {
    ...mapActions("dashboard", ['loadTotalStatus']),

    changeCols(val) {
      this.$emit('changeCols', val);
    },
    downloadPNG() {
      const link = document.createElement("a");
      link.href = this.chartInstance.toBase64Image();
      link.download = "TotalStatus.png";
      link.click();
    },
    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
        await this.loadTotalStatus(this.selectedDate);
        this.graph(this.totalStatus.data);
        this.day = this.totalStatus.interval;
      } catch (error) {
        this.loading = false;
        this.showErrorMessage = true;
        this.messageError = error.message;
        this.$swal("Oops...", error.message, "error");
      }
    },

    onChartClick(event) {
      const activePoints = this.chartInstance.getElementsAtEventForMode(event, "nearest", { intersect: true }, false);
      this.listModal = [];
      if (activePoints.length > 0) {
        const chartIndex = activePoints[0].index;
        const label = this.chartInstance.data.labels[chartIndex];
        if (this.totalStatus.data && this.totalStatus.data[label].solicitations) {
          this.listModal = this.totalStatus.data[label].solicitations;
          this.titleModal = 'Solicitações por Status: ' + label;
          this.showModal = true;
        }
      }
    },

    graph(data) {
      const labels = Object.keys(data);
      const total = labels.map(label => data[label].total);
      const colors = labels.map(label => data[label].color);

      const ctx = this.$refs.totalSolicitationStatus;

      this.chartInstance = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data: total,
              backgroundColor: colors,
              borderColor: colors,
              borderWidth: 1,
              hoverOffset: 20
            },
          ],
        },
        options: {
          onClick: this.onChartClick,
          plugins: {
            datalabels: {
              anchor: 'center',
              align: 'center',
              formatter: (value, context) => {
                return value;
              },
              font: {
                size: 15,
                weight: 'bold'
              },
            }
          },
          responsive: true,
          maintainAspectRatio: false, // Desativa a manutenção automática da proporção
        },
        plugins: [ChartDataLabels]
      });
      this.loading = false;
    }
  },
  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>
<style scoped>
</style>
