import axios from "../axios.config";

const state = {
  graphWeekOpenClosed: [],
  solicitaionTypeOpenClosed: [],
  totalStatus: [],
  averageType: [],
  totalFinishSla: [],
};

const actions = {
  async loadGraphWeekOpenClosed({ commit }, params) {
    try {
      const { data } = await axios.get("/dashboard/week-open-closed", {
        params: {
          date: params.date,
          value: params.value,
          customDate: [params.customDate.start, params.customDate.end]
        }
      });
      commit("SET_GRAPH_WEEK_OPEN_CLOSED", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async loadSolicitaionTypeOpenClosed({ commit }, params) {
    try {
      const { data } = await axios.get("dashboard/solicitation-type-open-closed-day", {
        params: {
          date: params.date,
          value: params.value,
          customDate: [params.customDate.start, params.customDate.end]
        }
      });
      commit("SET_SOLICITATION_TYPE_OPEN_CLOSED", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async loadTotalStatus({ commit }, params) {
    try {
      const { data } = await axios.get("dashboard/total-status", {
        params: {
          date: params.date,
          value: params.value,
          customDate: [params.customDate.start, params.customDate.end]
        }
      });
      commit("SET_TOTAL_STATUS", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async loadAverageType({ commit }, params) {
    try {
      const { data } = await axios.get("dashboard/average-type", {
        params: {
          date: params.date,
          value: params.value,
          customDate: [params.customDate.start, params.customDate.end]
        }
      });
      commit("SET_AVERAGE_TYPE", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async loadTotalFinishSla({ commit }, params) {
    try {
      const { data } = await axios.get("dashboard/total-finish-sla", {
        params: {
          date: params.date,
          value: params.value,
          customDate: [params.customDate.start, params.customDate.end]
        }
      });
      commit("SET_TOTAL_FINISH_SLA", data);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
};

const mutations = {
  SET_GRAPH_WEEK_OPEN_CLOSED: (state, value) => {
    state.graphWeekOpenClosed = value.data;
  },
  SET_SOLICITATION_TYPE_OPEN_CLOSED: (state, value) => {
    state.solicitaionTypeOpenClosed = value.data;
  },
  SET_TOTAL_STATUS: (state, value) => {
    state.totalStatus = value.data;
  },
  SET_AVERAGE_TYPE: (state, value) => {
    state.averageType = value.data;
  },
  SET_TOTAL_FINISH_SLA: (state, value) => {
    state.totalFinishSla = value.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
