<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined class="mx-auto" elevation="2">
          <div class="d-flex justify-end pr-4 pt-2">
            <v-icon @click="downloadPNG" class="ml-2" style="color: #4d638c;">mdi-file-png-box</v-icon>
            <v-icon v-if="showMdiWindowMax" @click="changeCols('12'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-maximize</v-icon>
            <v-icon v-else @click="changeCols('6'); showMdiWindowMax = !showMdiWindowMax" class="ml-2">mdi-window-restore</v-icon>
          </div>
          <v-card-title class="mt-n5" style="display: flex; justify-content: center;">
            <h4>Tipos de Solicitações - Média de Tempo SLA - ({{day}})</h4>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                  style="cursor: pointer;"
                >
                  mdi-information
                </v-icon>
              </template>

              <span>
                Esta consulta recupera a média de tempo concluído de cada Tipo de Solicitação,  no periodo ({{day}}).
              </span>
            </v-tooltip>
          </v-card-title>
          <v-card-title class="mt-n5" style="display: flex; justify-content: center;">
            <h5>Tempo médio Geral: {{avg_time_global}} horas</h5>
          </v-card-title>
          <v-col v-if="loading" class="col-md-12 d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col v-if="showErrorMessage" class="col-md-12 d-flex justify-center align-center">
            {{ messageError }}
          </v-col>
          <div v-show="!loading" style="position: relative; height: 100%; width: 100%">
            <canvas ref="slaChart"></canvas>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedDate: {
      type: Object,
    },
    day: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: false,
      showErrorMessage: false,
      messageError: '',
      chartInstance: null,
      showMdiWindowMax: true,
      avg_time_global: '',
    };
  },
  mounted() {},
  computed: {
    ...mapState("dashboard", ["averageType"])
  },
  methods: {
    ...mapActions("dashboard", ["loadAverageType"]),

    changeCols(val) {
      this.$emit('changeCols', val);
    },
    downloadPNG() {
      const link = document.createElement("a");
      link.href = this.chartInstance.toBase64Image();
      link.download = "AverageType.png";
      link.click();
    },
    async loadDataComponent() {
      try {
        this.loading = true;
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
        await this.loadAverageType(this.selectedDate);
        this.createChart(this.averageType.data);
        this.day = this.averageType.interval;
        this.avg_time_global = this.averageType.avg_time_global;
      } catch (error) {
        this.loading = false;
        this.showErrorMessage = true;
        this.messageError = error.message;
        await this.$swal("Oops...", error.message, "error");
      }
    },

    createChart(data) {
      this.loading = true;
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const labels = data.map((item) => item.type);
      const avgTimes = data.map((item) => item.avg_time);
      const slaExpecteds = data.map((item) => item.avg_expected);

      const maxValue = Math.max(...avgTimes, ...slaExpecteds);
      const maxScale = Math.ceil(maxValue / 5) * 6;

      const ctx = this.$refs.slaChart;

      this.chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'Tempo médio (horas)',
              data: avgTimes,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              categoryPercentage: 0.8,
            },
            {
              label: 'Tempo médio esperado',
              data: slaExpecteds,
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              categoryPercentage: 0.8,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              formatter: (value, context) => {
                return value;
              },
              font: {
                weight: 'bold'
              },
            },
            legend: {
              display: true,
              position: 'top',
              labels: {
                usePointStyle: true,
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const value =
                    context.dataset.label === '% Dentro do SLA'
                      ? `${context.raw}`
                      : `${context.raw} horas`;
                  return `${context.dataset.label}: ${value}`;
                },
              },
            },

          },
          indexAxis: 'y',
          scales: {
            x: {
              max: maxScale,
              beginAtZero: true,
              title: {
                display: true,
                text: 'Tempo / Horas',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Tipos de Solicitações',
              },
            },
          },
        },
        plugins: [ChartDataLabels]
      });
      this.loading = false;
    },
  },
  watch: {
    async selectedDate(newDate) {
      await this.loadDataComponent(newDate);
    }
  },
};
</script>

<style scoped></style>