<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">
          {{field == "suspensao_perda_rouboComponent" ? "Suspensão Perda ou Roubo" : "Suspensão de Linha Voluntária"}}
        </v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      md="6"
      sm="12"
      v-for="(lineSelected, index) in lineSelect"
      :key="index"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="primary white--text">
          <div class="headerClass">
            {{lineSelected.line.ddi ? lineSelected.line.ddi+' ('+lineSelected.line.ddd+') '+lineSelected.line.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <LineComponent
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                :selectedLine="lineSelected.line"
                @save="
                  lineSelected.line = $event;
                  updateSelectedLine($event, lineSelected, index)
                "
              ></LineComponent>
            </v-col>
            <ListPackage
                v-if="loadingLinePackages[index]"
                :loadingPackage="loadingLinePackages[index].value"
                :lineSelected="lineSelected"
            >
            </ListPackage>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import ListPackage from "@/components/package/ListPackage";
import LineComponent from "@/components/forms/LineComponent.vue";
import isEmpty from "lodash/isEmpty";
export default {
  components: { LineComponent, ListPackage},
  props: {
    field: {},
    index: {},
    uniqueKeyStorage: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lineSelect: [],
      loading: true,
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"],
      loadingLinePackages: []
    };
  },
  async mounted() {
    const dataLocalStorage = await this.loadDataLocalStorage(this.uniqueKeyStorage);
    if (!isEmpty(dataLocalStorage) && this.page === 'wizard') {
      this.resultStorage = dataLocalStorage;
      this.lineSelect = Object.assign([], this.resultStorage);
      this.lineSelect.forEach(value => {
        this.loadingLinePackages.push({ value: false });
      });
    } else {
      await this.clickAddNewLine();
    }
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLinePackages"]),
    ...mapActions("solicitation", ["loadDataLocalStorage"]),
    ...mapMutations({ setModel: "forms/setModelVoluntaryLineSuspension" }),
    async clickAddNewLine() {
      const newLine = {
        packages: [],
        line: {}
      };

      this.loadingLinePackages.push({
        value: false
      });
      this.lineSelect = [...this.lineSelect, newLine];
      this.updateValues();
    },
    async updateSelectedLine(line, lineSelected, index) {
      this.show = this.show ? false : lineSelected;
      this.loadingLinePackages[index].value = true;
      if (line.id_linha) {
        lineSelected.packages = [];
        await this.loadLinePackages({
          id_linha: line.id_linha,
          type: "old"
        }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        lineSelected.packages = this.linePackages.data;
      }
      this.updateValues();
      this.show = false;
      this.loadingLinePackages[index].value = false;
    },
    updateValues() {
      const payload = {
        step: this.index,
        selected: {
          model: {lines: this.lineSelect},
          revision: {lines: this.lineSelect}
        }
      }
      this.setModel(payload);
      if (this.page === 'wizard') {
        localStorage.setItem(this.uniqueKeyStorage, JSON.stringify(this.lineSelect));
      }
    },
    closeLine(index) {
      if (index > -1) {
        this.lineSelect.splice(index, 1);
        this.loadingLinePackages.splice(index, 1);
        this.updateValues();
      }
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
        regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
